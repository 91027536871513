PaymentAndPolicy = React.createClass({
  propTypes: {
    media_url: React.PropTypes.string.isRequired,
    payment_setup: React.PropTypes.bool.isRequired,
    default_policy: React.PropTypes.string.isRequired,
    default_cancellation_policy: React.PropTypes.string.isRequired,
    tax_rate: React.PropTypes.number,
    tax_groups: React.PropTypes.arrayOf(React.PropTypes.object),
    tax_group_id: React.PropTypes.string,
    currency_symbol: React.PropTypes.string.isRequired,
    initial_require_credit_card: React.PropTypes.bool.isRequired,
    initial_party_size_min: React.PropTypes.number,
    initial_payment_rule: React.PropTypes.string,
    initial_cost: React.PropTypes.number,
    initial_charge_type: React.PropTypes.string,
    initial_apply_tax_rate: React.PropTypes.bool,
    initial_gratuity: React.PropTypes.number,
    apply_service_charge: React.PropTypes.bool,
    service_charge_type: React.PropTypes.string,
    service_charge: React.PropTypes.number,
    apply_gratuity_charge: React.PropTypes.bool,
    gratuity_type: React.PropTypes.string,
    require_gratuity_charge: React.PropTypes.bool,
    initial_policy_type: React.PropTypes.string,
    initial_policy: React.PropTypes.string,
    initial_cancellation_policy_type: React.PropTypes.string,
    initial_cancellation_policy: React.PropTypes.string,
    auto_charge_type: React.PropTypes.string,
    auto_charge_amount: React.PropTypes.number,
    auto_charge_amount_in_cents: React.PropTypes.number,
    auto_charge_amount_type: React.PropTypes.string,
    auto_cutoff_num: React.PropTypes.number,
    auto_cutoff_type: React.PropTypes.string,
    auto_cutoff_hour: React.PropTypes.string,
    cancel_cutoff_num: React.PropTypes.number,
    cancel_cutoff_type: React.PropTypes.string,
    cancel_cutoff_hour: React.PropTypes.string,
    can_save_card: React.PropTypes.bool,
    default_service_charge: React.PropTypes.number,
    default_gratuity: React.PropTypes.number,
    venue_settings: React.PropTypes.object,
    venue_id: React.PropTypes.string,
    changeBookingPolicyID: React.PropTypes.func.isRequired,
    changePolicyType: React.PropTypes.func.isRequired,
    changeCancellationPolicyID: React.PropTypes.func.isRequired,
    changeCancellationPolicyType: React.PropTypes.func.isRequired,
  },

  chargeTypes: {
    person: 'per person',
    person_slot: 'per person per 15 minutes',
    reservation: 'per reservation',
    reservation_slot: 'per reservation per 15 minutes',
  },

  getInitialState: function () {
    var payment_rule = this.props.can_save_card ? this.props.initial_payment_rule : 'advanced_payment'
    var policy_type =
      this.props.initial_policy_type === 'custom' || this.props.initial_policy_type === 'default'
        ? this.props.initial_policy_type
        : 'default'
    var cancellation_policy_type =
      this.props.initial_cancellation_policy_type === 'custom' || this.props.initial_cancellation_policy_type === 'default'
        ? this.props.initial_cancellation_policy_type
        : 'default'
    var booking_policy_id =
      this.props.initial_policy_type && this.props.initial_policy_type !== 'custom' && this.props.initial_policy_type !== 'default'
        ? this.props.initial_policy_type
        : null
    var cancellation_policy_id =
      this.props.initial_cancellation_policy_type &&
      this.props.initial_cancellation_policy_type !== 'custom' &&
      this.props.initial_cancellation_policy_type !== 'default'
        ? this.props.initial_cancellation_policy_type
        : null
    return {
      require_credit_card: this.props.initial_require_credit_card,
      require_for_all_party_sizes: !Boolean(this.props.initial_party_size_min),
      party_size_min: this.props.initial_party_size_min,
      payment_rule: payment_rule,
      cost: (this.props.initial_cost || 0).toFixed(2),
      charge_type: this.props.initial_charge_type || 'person',
      apply_tax_rate: this.props.initial_apply_tax_rate,
      tax_groups: this.props.tax_groups,
      tax_group_id: this.props.tax_group_id,
      apply_service_charge: this.props.apply_service_charge,
      service_charge_type: this.props.service_charge_type,
      service_charge: this.props.service_charge,
      apply_gratuity_charge: this.props.apply_gratuity_charge,
      gratuity_type: this.props.gratuity_type,
      require_gratuity_charge: this.props.require_gratuity_charge,
      gratuity: this.props.initial_gratuity,
      policy_type_option: this.props.initial_policy_type,
      policy: this.props.initial_policy || this.props.default_policy,
      cancellation_policy_type_option: this.props.initial_cancellation_policy_type,
      cancellation_policy: this.props.initial_cancellation_policy || this.props.default_cancellation_policy,
      auto_charge_type: this.props.auto_charge_type || 'NO_CHARGE',
      auto_charge_amount: ((this.props.auto_charge_amount_in_cents || 0) / 100).toFixed(2),
      auto_charge_amount_type: this.props.auto_charge_amount_type || 'person',
      auto_cutoff: _.isNumber(this.props.auto_cutoff_num),
      auto_cutoff_num: _.isNumber(this.props.auto_cutoff_num) ? this.props.auto_cutoff_num : 1,
      auto_cutoff_type: this.props.auto_cutoff_type || 'HOURS',
      auto_cutoff_hour: this.props.auto_cutoff_hour || 0,
      cancel_cutoff_num: _.isNumber(this.props.cancel_cutoff_num) ? this.props.cancel_cutoff_num : 1,
      cancel_cutoff_type: this.props.cancel_cutoff_type,
      cancel_cutoff_hour: this.props.cancel_cutoff_hour || 0,
      can_save_card: this.props.can_save_card || 0,
      default_service_charge: this.props.default_service_charge,
      default_gratuity: this.props.default_gratuity,
      booking_policy_options: {},
      payment_policy_options: {},
      showBookingPolicyTextArea: false,
      showCancellationPolicyTextArea: false,
      policy_type: policy_type,
      cancellation_policy_type: cancellation_policy_type,
      booking_policy_id: booking_policy_id,
      cancellation_policy_id: cancellation_policy_id,
    }
  },

  componentDidMount: function () {
    var params = $.param({ policy_categories: 'BOOKING,PAYMENT' })
    var policies_url = $.get(`/api-yoa/policies/${this.props.venue_id}`, params)
    $.when(policies_url)
      .done(
        function (policies_resp) {
          var policies = policies_resp.data.policies || []
          var booking_policy_options = _.reduce(
            _.sortBy(
              policies.filter(policy => policy.policy_category === 'BOOKING'),
              'created'
            ),
            (obj, item) => Object.assign(obj, { [item.id]: item.name }),
            {}
          )
          var payment_policy_options = _.reduce(
            _.sortBy(
              policies.filter(policy => policy.policy_category === 'PAYMENT'),
              'created'
            ),
            (obj, item) => Object.assign(obj, { [item.id]: item.name }),
            {}
          )
          this.setState({
            booking_policy_options: booking_policy_options,
            payment_policy_options: payment_policy_options,
          })
        }.bind(this)
      )
      .fail(function () {
        Interface._alert('Something went wrong, please try later!')
      })
    this.setState({
      showCancellationPolicyTextArea: this.props.initial_cancellation_policy_type !== 'custom',
      showBookingPolicyTextArea: this.props.initial_policy_type !== 'custom',
    })
    this.props.changeBookingPolicyID(this.state.booking_policy_id)
    this.props.changePolicyType(this.state.policy_type)
    this.props.changeCancellationPolicyID(this.state.cancellation_policy_id)
    this.props.changeCancellationPolicyType(this.state.cancellation_policy_type)
  },

  clickRequireCreditCard: function (event) {
    this.setState({
      require_credit_card: event.target.value === '1',
    })
  },

  clickRequireForPartySize: function (event) {
    this.setState({
      require_for_all_party_sizes: event.target.value === '1',
    })
  },

  changePartySizeMin: function (event) {
    this.setState({
      party_size_min: event.target.value,
    })
  },

  clickPaymentRule: function (event) {
    this.setState({
      payment_rule: event.target.value,
      auto_charge_type: event.target.value === 'advanced_payment' ? 'NO_REFUND' : 'NO_CHARGE',
      auto_cutoff: false,
    })
  },

  clickCancelCutoffSelect: function (event) {
    if (event.target.value === 'UP_UNTIL') {
      this.setState({
        cancel_cutoff_type: 'HOURS',
      })
    } else if (event.target.value === 'NEVER') {
      this.setState({
        cancel_cutoff_type: 'NEVER',
      })
    } else {
      this.setState({
        cancel_cutoff_type: null,
      })
    }
  },

  changeCost: function (event) {
    this.setState({
      cost: event.target.value,
    })
  },

  changeAutoChargeAmount: function (event) {
    this.setState({
      auto_charge_amount: event.target.value,
    })
  },

  changeChargeType: function (event) {
    this.setState({
      charge_type: event.target.value,
    })
  },

  changeAutoChargeAmountType: function (event) {
    this.setState({
      auto_charge_amount_type: event.target.value,
    })
  },

  changePolicyType: function (event) {
    var showBookingPolicyTextArea = event.target.value !== 'custom'
    var policy_type_option = event.target.value
    var policy_type = event.target.value === 'custom' ? event.target.value : 'default'
    var booking_policy_id = event.target.value !== 'custom' ? event.target.value : null
    this.setState({
      showBookingPolicyTextArea: showBookingPolicyTextArea,
      policy_type_option: policy_type_option,
      policy_type: policy_type,
      booking_policy_id: booking_policy_id,
    })
    this.props.changeBookingPolicyID(booking_policy_id)
    this.props.changePolicyType(policy_type)
  },

  changeCustomPolicy: function (event) {
    this.setState({
      policy: event.target.value,
    })
  },

  changeCancellationPolicyType: function (event) {
    var showCancellationPolicyTextArea = event.target.value !== 'custom'
    var cancellation_policy_type_option = event.target.value
    var cancellation_policy_type = event.target.value === 'custom' ? event.target.value : 'default'
    var cancellation_policy_id = event.target.value !== 'custom' ? event.target.value : null
    this.setState({
      showCancellationPolicyTextArea: showCancellationPolicyTextArea,
      cancellation_policy_type_option: cancellation_policy_type_option,
      cancellation_policy_type: cancellation_policy_type,
      cancellation_policy_id: cancellation_policy_id,
    })
    this.props.changeCancellationPolicyID(cancellation_policy_id)
    this.props.changeCancellationPolicyType(cancellation_policy_type)
  },

  changeCustomCancellationPolicy: function (event) {
    this.setState({
      cancellation_policy: event.target.value,
    })
  },

  clickApplyServiceCharge: function (event) {
    this.setState({
      apply_service_charge: !this.state.apply_service_charge,
    })
  },

  clickServiceChargeType: function (event) {
    this.setState({
      service_charge_type: event.target.value,
    })
  },

  changeServiceCharge: function (event) {
    this.setState({
      service_charge: event.target.value,
    })
  },

  clickGratuityType: function (event) {
    this.setState({
      gratuity_type: event.target.value,
    })
  },

  changeGratuity: function (event) {
    this.setState({
      gratuity: event.target.value,
    })
  },

  clickApplyGratuityCharge: function (event) {
    this.setState({
      apply_gratuity_charge: !this.state.apply_gratuity_charge,
    })
  },

  clickRequireGratuityCharge: function (event) {
    this.setState({
      require_gratuity_charge: !this.state.require_gratuity_charge,
    })
  },

  clickApplyTaxRate: function (event) {
    this.setState({
      apply_tax_rate: !this.state.apply_tax_rate,
      tax_group_id: !this.state.apply_tax_rate ? Object.keys(this.state.tax_groups)[0] : null,
    })
  },

  changeTaxGroup: function (event) {
    this.setState({
      tax_group_id: this.state.apply_tax_rate ? event.target.value : null,
    })
  },

  clickApplyCutoff: function (event) {
    this.setState({
      auto_cutoff: !this.state.auto_cutoff,
    })
  },

  clickAutoChargeType: function (event) {
    this.setState({
      auto_charge_type: event.target.value,
    })
  },

  radioCssStyle: function () {
    return {
      inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
      preLabelStyle: { paddingTop: '10px' },
      preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
      postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20, fontWeight: 500 },
    }
  },

  subTextCssStyle: function () {
    return {
      clear: 'both',
      color: '#aaa',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: 11,
      paddingTop: 5,
      paddingLeft: 19,
    }
  },

  cutoffTimeSelection: React.createClass({
    propTypes: {
      in_advance_of_text: React.PropTypes.string.isRequired,
    },

    getDefaultProps() {
      return {
        in_advance_of_text: 'in advance of',
      }
    },

    getInitialState: function () {
      return {
        type: this.props.type ? this.props.type : 'HOURS',
        num: this.props.num,
        hour: this.props.hour || '0',
        is_disabled: this.props.disabled,
      }
    },

    onChangeHandler: function (e) {
      var value = $(e.currentTarget).val()
      this.setState({
        type: value,
        hour: this.state.type === 'HOURS' ? null : this.state.hour,
      })
    },

    onNumChangeHandler: function (e) {
      this.setState({ num: $(e.currentTarget).val() })
    },

    onHoursChangeHandler: function (e) {
      this.setState({ hour: $(e.currentTarget).val() })
    },

    render: function () {
      var firstOptions = {
        MINUTES: 'minutes ' + this.props.in_advance_of_text,
        HOURS: 'hours ' + this.props.in_advance_of_text,
        DAYS: 'days ' + this.props.in_advance_of_text,
        WEEKS: 'weeks ' + this.props.in_advance_of_text,
        MONTHS: 'months ' + this.props.in_advance_of_text,
      }
      var secondOptions = _.extend(
        {},
        { 0: 'reservation time' },
        this.state.type === 'HOURS' ? [] : modules.accessslideout.getAvailableTimes()
      )
      return (
        <div>
          <div
            className="inline"
            style={{
              margin: '10px 0px',
              display: this.state.is_disabled ? 'none' : 'block',
            }}
          >
            <modules.formElements.create
              type="number"
              min="1"
              onChangeHandler={this.onNumChangeHandler}
              value={this.state.num}
              disabled={this.state.is_disabled}
              validator="not_empty"
              name={this.props.name_1}
              validator_message="Please select a number"
              inputCss={{ width: 40, padding: '6px 8px' }}
              style={{ clear: 'both', width: 60, backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
            />
            <modules.formElements.create
              type="select"
              disabled={this.state.is_disabled}
              name={this.props.name_2}
              onChangeHandler={this.onChangeHandler}
              media_url={this.props.media_url}
              preInputStyle={{ width: '10.5em' }}
              options={firstOptions}
              value={this.state.type}
              inputCss={{ padding: '6px 8px', border: 0 }}
              style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
            />
            <modules.formElements.create
              type="select"
              name={this.props.name_3}
              onChangeHandler={this.onHoursChangeHandler}
              preInputStyle={{ width: '130px' }}
              value={this.state.hour}
              media_url={this.props.media_url}
              options={secondOptions}
              inputCss={{ padding: '6px 8px', border: 0 }}
              style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right', marginRight: '0' }}
            />
          </div>
        </div>
      )
    },
  }),

  paymentsSetupContent: function () {
    var tax_rate_set = !!this.props.tax_rate
    var tax_rate_label
    var settings_url = Pmp.Manager.Global._managerBaseUrl + '/manage/bank'
    if (tax_rate_set) {
      tax_rate_label = <span>Apply {this.props.tax_rate}% tax</span>
    } else {
      tax_rate_label = (
        <span>
          Apply tax (tax rate needs to be set in <a href={settings_url}>Settings</a>)
        </span>
      )
    }
    var apply_tax_rate_classes = 'form-element checkbox'
    var apply_tax_rate_disabled = !this.state.require_credit_card || _.isEmpty(this.state.tax_groups)
    if (apply_tax_rate_disabled) {
      apply_tax_rate_classes += ' disabled'
    } else if (this.state.apply_tax_rate) {
      apply_tax_rate_classes += ' checked'
    }
    var charge_checkbox_disabled = !this.state.require_credit_card
    var apply_service_charge_class = (apply_gratuity_charge_class = require_gratuity_charge_class = 'form-element checkbox')
    if (this.state.apply_service_charge) {
      apply_service_charge_class += ' checked'
    }
    if (this.state.apply_gratuity_charge) {
      apply_gratuity_charge_class += ' checked'
    }
    if (this.state.require_gratuity_charge) {
      require_gratuity_charge_class += ' checked'
    }
    return (
      <div>
        <div style={{ marginBottom: 10 }} className="inline">
          <p className="group-label" style={{ color: '#777' }}>
            <span>Require credit card with reservation booking</span>
          </p>
          <div className="radioset">
            <modules.formElements.create
              type="radio"
              onClickHandler={this.clickRequireCreditCard}
              value="1"
              defaultradio={this.state.require_credit_card}
              name="require_credit_card"
              id="require-credit-card-1"
              testId="sr-radio-require-credit-card-1"
              postLabelStyle={{ width: 60, fontWeight: 500 }}
              label="Yes"
            />
            <modules.formElements.create
              type="radio"
              onClickHandler={this.clickRequireCreditCard}
              value="0"
              name="require_credit_card"
              id="require-credit-card-0"
              testId="sr-radio-require-credit-card-0"
              defaultradio={!this.state.require_credit_card}
              postLabelStyle={{ width: 60, fontWeight: 500 }}
              label="No"
            />
          </div>
        </div>

        {!this.state.require_credit_card ? undefined : (
          <div>
            <div className="inline">
              <p className="group-label" style={{ color: '#777' }}>
                <span>Require credit card for</span>
              </p>

              <modules.formElements.create
                type="radio"
                onClickHandler={this.clickRequireForPartySize}
                disabled={!this.state.require_credit_card}
                value="1"
                name="ps"
                defaultradio={this.state.require_for_all_party_sizes}
                id="require-for-all-party-sizes-1"
                {...this.radioCssStyle()}
                label="All party sizes"
              />
              <modules.formElements.create
                type="radio"
                onClickHandler={this.clickRequireForPartySize}
                disabled={!this.state.require_credit_card}
                value="0"
                name="ps"
                id="require-for-all-party-sizes-0"
                defaultradio={!this.state.require_for_all_party_sizes}
                {...this.radioCssStyle()}
                label="Party sizes greater than"
              />

              {!this.state.require_for_all_party_sizes ? (
                <div className="inline" style={{ marginTop: 10 }}>
                  <modules.formElements.create
                    type="number"
                    min="1"
                    onChangeHandler={this.changePartySizeMin}
                    disabled={!this.state.require_credit_card}
                    value={this.state.party_size_min}
                    name="cc_party_size_min"
                    inputCss={{ padding: '6px 8px' }}
                    style={{ clear: 'both', backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
                  />
                </div>
              ) : undefined}
            </div>

            <div className="inline">
              <p className="group-label" style={{ color: '#777' }}>
                <span>Payment Rules</span>
              </p>

              {this.state.can_save_card ? (
                <div>
                  <modules.formElements.create
                    type="radio"
                    onClickHandler={this.clickPaymentRule}
                    disabled={!this.state.require_credit_card}
                    value="save_for_later"
                    defaultradio={this.state.payment_rule === 'save_for_later' || !this.state.payment_rule}
                    name="cc_payment_rule"
                    id="payment-rule-save-for-later"
                    {...this.radioCssStyle()}
                    label="Save credit card details for later"
                  />

                  <p className="label" style={this.subTextCssStyle()}>
                    You'll be able to charge the card up to 7 days after the reservation date
                  </p>
                  <modules.formElements.create
                    type="radio"
                    onClickHandler={this.clickPaymentRule}
                    disabled={!this.state.require_credit_card}
                    value="advanced_payment"
                    name="cc_payment_rule"
                    id="payment-rule-advanced-payment"
                    defaultradio={this.state.payment_rule === 'advanced_payment'}
                    {...this.radioCssStyle()}
                    label="Advanced payment required"
                  />
                </div>
              ) : (
                <input type="hidden" name="cc_payment_rule" value="advanced_payment" />
              )}
              <p className="label" style={this.subTextCssStyle()}>
                Credit card will be charged at the time of booking
              </p>

              {this.state.payment_rule === 'advanced_payment' ? (
                <div>
                  <div className="inline" style={{ marginTop: 10 }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: 3, clear: 'both', float: 'left' }}>
                      &nbsp;&nbsp;{this.props.currency_symbol}
                      <input
                        type="number"
                        min="1"
                        onChange={this.changeCost}
                        disabled={!this.state.require_credit_card}
                        value={this.state.cost}
                        name="cc_cost"
                        style={{ border: 0, outline: 'none', width: '6em' }}
                      />
                    </div>
                    <modules.formElements.create
                      type="select"
                      onChangeHandler={this.changeChargeType}
                      disabled={!this.state.require_credit_card}
                      value={this.state.charge_type}
                      media_url={this.props.media_url}
                      name="cc_charge_type"
                      options={this.chargeTypes}
                      preInputStyle={{ width: '16em' }}
                      inputCss={{ padding: '6px 8px' }}
                      style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
                    />
                  </div>

                  <div className="inline" style={{ marginTop: 10 }}>
                    <div className={apply_service_charge_class}>
                      <label htmlFor="apply-service-charge">
                        <p className="input">
                          <input
                            type="checkbox"
                            id="apply-service-charge"
                            disabled={charge_checkbox_disabled}
                            onChange={this.clickApplyServiceCharge}
                            checked={this.state.apply_service_charge}
                            name="apply_service_charge"
                            value="1"
                          />
                        </p>
                        <p className="label post" style={{ fontWeight: 400 }}>
                          Apply Service Charge (taxed)
                        </p>
                      </label>
                    </div>
                  </div>
                  {this.state.apply_service_charge && (
                    <div className="inline">
                      <modules.formElements.create
                        type="radio"
                        onClickHandler={this.clickServiceChargeType}
                        value="DEFAULT_SERVICE_CHARGE"
                        defaultradio={this.state.service_charge_type === 'DEFAULT_SERVICE_CHARGE'}
                        name="service_charge_type"
                        id="service-charge-type-default-service-charge"
                        {...this.radioCssStyle()}
                        label={'Default Service Charge (' + this.state.default_service_charge + '%)'}
                      />
                      <modules.formElements.create
                        type="radio"
                        onClickHandler={this.clickServiceChargeType}
                        value="SPECIFIC_SERVICE_CHARGE"
                        defaultradio={this.state.service_charge_type === 'SPECIFIC_SERVICE_CHARGE'}
                        name="service_charge_type"
                        id="service-charge-type-specific-service-charge"
                        {...this.radioCssStyle()}
                        label="Specific Service Charge"
                      />
                    </div>
                  )}
                  {this.state.apply_service_charge && this.state.service_charge_type === 'SPECIFIC_SERVICE_CHARGE' && (
                    <div className="inline" style={{ marginTop: 10, marginLeft: 20 }}>
                      <div style={{ border: '1px solid #ccc', borderRadius: 3, width: 110, position: 'relative' }}>
                        <input
                          type="number"
                          onChange={this.changeServiceCharge}
                          disabled={!this.state.require_credit_card}
                          value={this.state.service_charge}
                          name="service_charge"
                          style={{ border: 0, outline: 'none', width: 80 }}
                        />
                        <span style={{ right: 7, top: 7 }}>%</span>
                      </div>
                    </div>
                  )}
                  <div className="inline">
                    <div className={apply_tax_rate_classes}>
                      <label htmlFor="apply-tax-rate">
                        <p className="input">
                          <input
                            type="checkbox"
                            id="apply-tax-rate"
                            disabled={apply_tax_rate_disabled}
                            onChange={this.clickApplyTaxRate}
                            checked={this.state.apply_tax_rate}
                            name="cc_apply_tax_rate"
                            value="1"
                          />
                        </p>
                        <p className="label post" style={{ fontWeight: 400 }}>
                          Apply Tax
                        </p>
                      </label>
                    </div>
                  </div>
                  {this.state.apply_tax_rate && (
                    <div className="inline" style={{ marginTop: 10 }}>
                      <modules.formElements.create
                        type="select"
                        onChangeHandler={this.changeTaxGroup}
                        value={this.state.tax_group_id}
                        name="tax_group_id"
                        options={this.state.tax_groups}
                        media_url={this.props.media_url}
                        inputCss={{ padding: '6px 8px' }}
                        style={{ backgroundColor: '#FFF', float: 'left', clear: 'right', fontWeight: 400 }}
                      />
                    </div>
                  )}
                  <div className="inline">
                    <div className={apply_gratuity_charge_class}>
                      <label htmlFor="apply-gratuity-charge">
                        <p className="input">
                          <input
                            type="checkbox"
                            id="apply-gratuity-charge"
                            disabled={charge_checkbox_disabled}
                            onChange={this.clickApplyGratuityCharge}
                            checked={this.state.apply_gratuity_charge}
                            name="apply_gratuity_charge"
                            value="1"
                          />
                        </p>
                        <p className="label post" style={{ fontWeight: 400 }}>
                          Apply Gratuity (not taxed)
                        </p>
                      </label>
                    </div>
                  </div>
                  {this.state.apply_gratuity_charge && (
                    <div className="inline">
                      <modules.formElements.create
                        type="radio"
                        onClickHandler={this.clickGratuityType}
                        value="DEFAULT_GRATUITY"
                        defaultradio={this.state.gratuity_type === 'DEFAULT_GRATUITY'}
                        name="gratuity_type"
                        id="gratuity_type-default-gratuity"
                        {...this.radioCssStyle()}
                        label={'Default gratuity (' + this.state.default_gratuity + '%)'}
                      />
                      <modules.formElements.create
                        type="radio"
                        onClickHandler={this.clickGratuityType}
                        value="SPECIFIC_GRATUITY"
                        defaultradio={this.state.gratuity_type === 'SPECIFIC_GRATUITY'}
                        name="gratuity_type"
                        id="gratuity_type-specific-gratuity"
                        {...this.radioCssStyle()}
                        label="Specific Gratuity"
                      />
                      {this.state.apply_gratuity_charge && this.state.gratuity_type === 'SPECIFIC_GRATUITY' && (
                        <div className="inline" style={{ marginTop: 10, marginLeft: 20 }}>
                          <div style={{ border: '1px solid #ccc', borderRadius: 3, width: 110, position: 'relative' }}>
                            <input
                              type="number"
                              onChange={this.changeGratuity}
                              disabled={!this.state.require_credit_card}
                              value={this.state.gratuity}
                              name="cc_gratuity"
                              style={{ border: 0, outline: 'none', width: 80 }}
                            />
                            <span style={{ right: 7, top: 7 }}>%</span>
                          </div>
                        </div>
                      )}
                      <modules.formElements.create
                        type="radio"
                        onClickHandler={this.clickGratuityType}
                        value="CLIENT_GRATUITY"
                        defaultradio={this.state.gratuity_type === 'CLIENT_GRATUITY'}
                        name="gratuity_type"
                        id="gratuity_type-client-gratuity"
                        {...this.radioCssStyle()}
                        label="Allow Client to Select Gratuity"
                      />
                      {this.state.apply_gratuity_charge && this.state.gratuity_type === 'CLIENT_GRATUITY' && (
                        <div className="inline" style={{ marginLeft: 20 }}>
                          <div className={require_gratuity_charge_class}>
                            <label htmlFor="require-gratuity-charge">
                              <p className="input">
                                <input
                                  type="checkbox"
                                  id="require-gratuity-charge"
                                  disabled={charge_checkbox_disabled}
                                  onChange={this.clickRequireGratuityCharge}
                                  checked={this.state.require_gratuity_charge}
                                  name="require_gratuity_charge"
                                  value="1"
                                />
                              </p>
                              <p className="label post" style={{ fontWeight: 400 }}>
                                Require Client to Select
                              </p>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : undefined}
              {this.state.payment_rule !== 'advanced_payment' && this.autoCancellation()}
            </div>
          </div>
        )}
        {(this.state.payment_rule === 'advanced_payment' || !this.state.require_credit_card) && this.autoRefunds()}
      </div>
    )
  },

  autoRefunds: function () {
    return (
      <div className="inline">
        <p className="group-label" style={{ color: '#777' }}>
          <span>Automatic Refund</span>
        </p>

        <modules.formElements.create
          type="radio"
          onClickHandler={this.clickAutoChargeType}
          value="NO_REFUND"
          defaultradio={['NO_CHARGE', 'NO_REFUND'].indexOf(this.state.auto_charge_type) >= 0}
          name="auto_charge_type"
          id="payment-rule-auto_charge_type_no_refund"
          {...this.radioCssStyle()}
          label="No Refund"
        />
        <modules.formElements.create
          type="radio"
          onClickHandler={this.clickAutoChargeType}
          value="FULL_REFUND"
          defaultradio={this.state.auto_charge_type === 'FULL_REFUND'}
          name="auto_charge_type"
          id="payment-rule-auto_charge_type_full_refund"
          {...this.radioCssStyle()}
          label="Full Refund"
        />
        <modules.formElements.create
          type="radio"
          onClickHandler={this.clickAutoChargeType}
          value="PARTIAL_REFUND"
          defaultradio={this.state.auto_charge_type === 'PARTIAL_REFUND'}
          name="auto_charge_type"
          id="payment-rule-auto_charge_type_partial_refund"
          {...this.radioCssStyle()}
          label="Partial Refund"
        />
        {this.state.auto_charge_type === 'PARTIAL_REFUND' ? (
          <p className="label" style={this.subTextCssStyle()}>
            Percentage of Advanced Payment
          </p>
        ) : null}
        {this.state.auto_charge_type === 'PARTIAL_REFUND' ? (
          <div className="inline" style={{ marginTop: 10 }}>
            <div style={{ border: '1px solid #ccc', borderRadius: 3, clear: 'both', float: 'left', width: 110 }}>
              <input
                type="number"
                min="1"
                max="100"
                onChange={this.changeAutoChargeAmount}
                value={parseInt(this.state.auto_charge_amount, 10)}
                name="auto_charge_amount"
                style={{ border: 0, outline: 'none', width: 80 }}
              />
              <span style={{ right: 7, top: 7 }}>%</span>
            </div>
          </div>
        ) : null}
        {['NO_CHARGE', 'NO_REFUND'].indexOf(this.state.auto_charge_type) < 0 ? (
          <div className="inline" style={{ marginTop: 10 }}>
            <div className={'form-element checkbox ' + (this.state.auto_cutoff ? 'checked' : '')}>
              <label htmlFor="auto_cutoff">
                <p className="input">
                  <input
                    type="checkbox"
                    id="auto_cutoff"
                    onChange={this.clickApplyCutoff}
                    checked={this.state.auto_cutoff}
                    name="auto_cutoff"
                    value="1"
                  />
                </p>

                <p className="label post" style={{ fontWeight: 400 }}>
                  Set a refund cut-off time
                </p>
              </label>
            </div>
            <p className="label" style={this.subTextCssStyle()}>
              Automatic refund will not be triggered if cancelled after this time
            </p>
          </div>
        ) : null}
        {this.state.auto_cutoff && ['NO_CHARGE', 'NO_REFUND'].indexOf(this.state.auto_charge_type) < 0 ? (
          <div className="inline">
            <this.cutoffTimeSelection
              include_title={true}
              ref={'time_selection_' + this.props.index}
              media_url={this.props.media_url}
              name_1={'auto_cutoff_num'}
              name_2={'auto_cutoff_type'}
              name_3={'auto_cutoff_hour'}
              num={this.props.auto_cutoff_num}
              type={this.props.auto_cutoff_type}
              hour={Pmp.Utils.militaryTimeToAMPM(this.props.auto_cutoff_hour)}
            />
          </div>
        ) : undefined}
      </div>
    )
  },

  autoCancellation: function () {
    return (
      <div>
        <div className="inline">
          <p className="group-label" style={{ color: '#777' }}>
            <span>Automatically Charge Cancellation/No-Show Fee</span>
          </p>

          <modules.formElements.create
            type="radio"
            onClickHandler={this.clickAutoChargeType}
            value="NO_CHARGE"
            defaultradio={this.state.auto_charge_type === 'NO_CHARGE' || !this.state.auto_charge_type}
            name="auto_charge_type"
            id="payment-rule-auto_charge_type_no_charge"
            {...this.radioCssStyle()}
            label="No Charge"
          />
          <modules.formElements.create
            type="radio"
            onClickHandler={this.clickAutoChargeType}
            value="CANCELLATION_NO_SHOW_CHARGE"
            defaultradio={this.state.auto_charge_type === 'CANCELLATION_NO_SHOW_CHARGE' || !this.state.auto_charge_type}
            name="auto_charge_type"
            id="payment-rule-auto_charge_type_auto"
            {...this.radioCssStyle()}
            label="Automatic Charge"
          />
          {this.state.auto_charge_type === 'CANCELLATION_NO_SHOW_CHARGE' ? (
            <div className="inline" style={{ marginTop: 10 }}>
              <div style={{ border: '1px solid #ccc', borderRadius: 3, clear: 'both', float: 'left' }}>
                &nbsp;&nbsp;{this.props.currency_symbol}
                <input
                  type="number"
                  min="1"
                  onChange={this.changeAutoChargeAmount}
                  disabled={!this.state.require_credit_card}
                  value={this.state.auto_charge_amount}
                  name="auto_charge_amount"
                  style={{ border: 0, outline: 'none', width: '6em' }}
                />
              </div>
              <modules.formElements.create
                type="select"
                onChangeHandler={this.changeAutoChargeAmountType}
                disabled={!this.state.require_credit_card}
                value={this.state.auto_charge_amount_type}
                media_url={this.props.media_url}
                name="auto_charge_amount_type"
                options={this.chargeTypes}
                preInputStyle={{ width: '16em' }}
                inputCss={{ padding: '6px 8px' }}
                style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right', marginLeft: 10 }}
              />
            </div>
          ) : null}
        </div>
        {['NO_CHARGE', 'NO_REFUND'].indexOf(this.state.auto_charge_type) < 0 ? (
          <div className="inline" style={{ marginTop: 10 }}>
            <div className={'form-element checkbox ' + (this.state.auto_cutoff ? 'checked' : '')}>
              <label htmlFor="auto-cut-off">
                <p className="input">
                  <input
                    type="checkbox"
                    id="auto-cut-off"
                    onChange={this.clickApplyCutoff}
                    checked={this.state.auto_cutoff}
                    name="auto_cutoff"
                    value="1"
                  />
                </p>
                <p className="label post" style={{ fontWeight: 400 }}>
                  Set a charge cut-off
                </p>
              </label>
            </div>
            <p className="label" style={this.subTextCssStyle()}>
              Automatic charge will only be triggered after this time
            </p>
          </div>
        ) : null}
        {this.state.auto_cutoff && ['NO_CHARGE', 'NO_REFUND'].indexOf(this.state.auto_charge_type) < 0 ? (
          <div className="inline">
            <this.cutoffTimeSelection
              include_title={true}
              ref={'time_selection_' + this.props.index}
              media_url={this.props.media_url}
              name_1={'auto_cutoff_num'}
              name_2={'auto_cutoff_type'}
              name_3={'auto_cutoff_hour'}
              num={this.props.auto_cutoff_num}
              type={this.props.auto_cutoff_type}
              hour={Pmp.Utils.militaryTimeToAMPM(this.props.auto_cutoff_hour)}
            />
          </div>
        ) : undefined}
      </div>
    )
  },

  paymentsNotSetupContent: function () {
    return (
      <div
        style={{
          borderRadius: '4px',
          clear: 'both',
          display: 'flex',
          padding: '1em',
          marginTop: '1em',
          backgroundColor: 'rgba(62, 147, 209, 0.20)',
          fontSize: '11px',
        }}
      >
        <div style={{ flexGrow: '1' }}>
          <div>
            <div style={{ fontWeight: 'bold' }}>Get Paid Through Your Reservation & Events Widget</div>
            <div style={{ color: '#425466', marginTop: '2px' }}>Connect a processor in minutes to enable online payments.</div>
          </div>
        </div>
        <div className="button" style={{ height: 'fit-content' }}>
          <a
            style={{ minWidth: 'fit-content', padding: '0 1em', fontSize: '12px', lineHeight: '2.5em !important', height: '2.5em' }}
            href={`/manager2/${window.globalInit.venueUrlKey}/settings/payment-integration/view`}
            target="_blank"
          >
            Connect
          </a>
        </div>
      </div>
    )
  },

  render: function () {
    var policy = this.state.policy_type === 'custom' ? this.state.policy : this.props.default_policy
    var cancellation_policy =
      this.state.cancellation_policy_type === 'custom' ? this.state.cancellation_policy : this.props.default_cancellation_policy
    var default_policy_option = {}
    return (
      <div>
        {this.props.payment_setup ? this.paymentsSetupContent() : this.paymentsNotSetupContent()}
        <div className="inline">
          <p className="group-label" style={{ color: '#777' }}>
            <span>Customer can modify/cancel via link in booking notification:</span>
          </p>
          <modules.formElements.create
            type="radio"
            onClickHandler={this.clickCancelCutoffSelect}
            value="ANY_TIME"
            defaultradio={!this.state.cancel_cutoff_type}
            name="cancel_cutoff_select"
            id="payment-rule-cancel_cutoff_select_any_time"
            testId="sr-radio-payment-rule-cancel-cutoff-select-any-time"
            {...this.radioCssStyle()}
            label="At Any Time"
          />
          <modules.formElements.create
            type="radio"
            onClickHandler={this.clickCancelCutoffSelect}
            value="NEVER"
            defaultradio={this.state.cancel_cutoff_type === 'NEVER'}
            name="cancel_cutoff_select"
            id="payment-rule-cancel_cutoff_select_never"
            testId="sr-radio-payment-rule-cancel-cutoff-select-never"
            {...this.radioCssStyle()}
            label="Never"
          />
          <modules.formElements.create
            type="radio"
            onClickHandler={this.clickCancelCutoffSelect}
            value="UP_UNTIL"
            defaultradio={this.state.cancel_cutoff_type && this.state.cancel_cutoff_type !== 'NEVER'}
            name="cancel_cutoff_select"
            id="payment-rule-cancel_cutoff_select_up_until"
            testId="sr-radio-payment-rule-cancel-cutoff-select-until"
            {...this.radioCssStyle()}
            label="Up until a cut-off time"
          />
          {this.state.cancel_cutoff_type && this.state.cancel_cutoff_type !== 'NEVER' && (
            <div className="inline">
              <this.cutoffTimeSelection
                include_title={true}
                ref={'time_selection_' + this.props.index}
                media_url={this.props.media_url}
                name_1={'cancel_cutoff_num'}
                name_2={'cancel_cutoff_type'}
                name_3={'cancel_cutoff_hour'}
                in_advance_of_text="before"
                num={this.props.cancel_cutoff_num}
                type={this.props.cancel_cutoff_type}
                hour={Pmp.Utils.militaryTimeToAMPM(this.props.cancel_cutoff_hour)}
              />
            </div>
          )}
        </div>
        <div className="inline">
          <div className="policy-controls">
            <div>
              <p className="group-label" style={{ color: '#777' }}>
                <span>Booking Policy</span>
              </p>
              <modules.formElements.create
                type="select"
                onChangeHandler={this.changePolicyType}
                value={this.state.policy_type_option}
                name="policy_type_option"
                options={Object.assign({}, this.state.booking_policy_options, default_policy_option, { custom: 'custom policy' })}
                media_url={this.props.media_url}
                inputCss={{ padding: '6px 8px' }}
                style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
              />
            </div>
          </div>
          {!this.state.showBookingPolicyTextArea && (
            <modules.formElements.create
              type="textarea"
              onChangeHandler={this.changeCustomPolicy}
              disabled={this.state.policy_type !== 'custom'}
              value={policy}
              name="booking_policy"
              inputCss={{ padding: '6px 8px' }}
              style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
            />
          )}
        </div>
        {this.state.require_credit_card ? (
          <div className="inline">
            <div className="policy-controls">
              <div>
                <p className="group-label" style={{ color: '#777' }}>
                  <span>Cancellation Policy (for bookings where credit card is required)</span>
                </p>
                <modules.formElements.create
                  type="select"
                  onChangeHandler={this.changeCancellationPolicyType}
                  value={this.state.cancellation_policy_type_option}
                  name="cancellation_policy_type_option"
                  options={Object.assign({}, this.state.payment_policy_options, default_policy_option, { custom: 'custom policy' })}
                  media_url={this.props.media_url}
                  inputCss={{ padding: '6px 8px' }}
                  style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
                />
              </div>
            </div>
            {!this.state.showCancellationPolicyTextArea && (
              <modules.formElements.create
                type="textarea"
                onChangeHandler={this.changeCustomCancellationPolicy}
                disabled={this.state.cancellation_policy_type !== 'custom'}
                value={cancellation_policy}
                name="cancellation_policy"
                inputCss={{ padding: '6px 8px' }}
                style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
              />
            )}
          </div>
        ) : undefined}
      </div>
    )
  },
})

PaymentAndPolicyView = React.createClass({
  propTypes: {
    currency_symbol: React.PropTypes.string.isRequired,
    require_credit_card: React.PropTypes.bool.isRequired,
    party_size_min: React.PropTypes.number,
    payment_rule: React.PropTypes.string,
    cost: React.PropTypes.number,
    charge_type: React.PropTypes.string,
    apply_tax_rate: React.PropTypes.bool,
    tax_groups: React.PropTypes.arrayOf(React.PropTypes.object),
    tax_group_id: React.PropTypes.string,
    gratuity: React.PropTypes.number,
    apply_service_charge: React.PropTypes.bool,
    service_charge_type: React.PropTypes.string,
    service_charge: React.PropTypes.number,
    apply_gratuity_charge: React.PropTypes.bool,
    gratuity_type: React.PropTypes.string,
    require_gratuity_charge: React.PropTypes.bool,
    policy_type: React.PropTypes.string,
    cancellation_policy_type: React.PropTypes.string,
    auto_charge_type: React.PropTypes.string,
    auto_charge_amount: React.PropTypes.number,
    auto_charge_amount_in_cents: React.PropTypes.number,
    auto_charge_amount_type: React.PropTypes.string,
    auto_cutoff_num: React.PropTypes.number,
    auto_cutoff_type: React.PropTypes.string,
    auto_cutoff_hour: React.PropTypes.string,
    auto_cutoff_hour_display: React.PropTypes.string,
    cancel_cutoff_num: React.PropTypes.number,
    cancel_cutoff_type: React.PropTypes.string,
    cancel_cutoff_hour: React.PropTypes.string,
    cancel_cutoff_hour_display: React.PropTypes.string,
    can_save_card: React.PropTypes.bool,
    ignore_cc_for_3p_bookers: React.PropTypes.bool,
    default_service_charge: React.PropTypes.number,
    default_gratuity: React.PropTypes.number,
    venue_id: React.PropTypes.string,
  },

  getInitialState: function () {
    return {
      policy_types: { default: 'default', custom: 'custom' },
    }
  },

  componentDidMount: function () {
    var booking_policy_params = $.param({ policy_categories: 'BOOKING' })
    var booking_policies_url = $.get(`/api-yoa/policies/${this.props.venue_id}`, booking_policy_params)
    $.when(booking_policies_url)
      .done(
        function (booking_policies_resp) {
          var booking_policies = booking_policies_resp.data.policies || []
          var policy_types = _.reduce(booking_policies, (obj, item) => Object.assign(obj, { [item.id]: item.name }), {})
          this.setState({
            policy_types: Object.assign({}, this.state.policy_types, policy_types),
          })
        }.bind(this)
      )
      .fail(function () {
        Interface._alert('Something went wrong, please try later!')
      })
  },

  render: function () {
    var payment_details, auto_charge_details, auto_cut_off_details
    if (this.props.payment_setup && this.props.require_credit_card) {
      var payment_rules
      var auto_charge_label =
        this.props.auto_charge_type === 'NO_CHARGE'
          ? 'No charge'
          : this.props.auto_charge_type === 'NO_REFUND'
          ? 'No refund'
          : this.props.auto_charge_type === 'FULL_REFUND'
          ? 'Full refund'
          : this.props.auto_charge_type === 'PARTIAL_REFUND'
          ? this.props.auto_charge_amount + '%'
          : this.props.auto_charge_type === 'CANCELLATION_NO_SHOW_CHARGE'
          ? this.props.currency_symbol +
            (this.props.auto_charge_amount_in_cents / 100).toFixed(2) +
            ' per ' +
            this.props.auto_charge_amount_type
          : null

      var autoCutOffDisplay = this.props.auto_cutoff_type
        ? (this.props.auto_cutoff_num || 'Same') +
          ' ' +
          this.props.auto_cutoff_type.toLowerCase() +
          (this.props.auto_cutoff_num ? ' in advance ' : ' ') +
          (this.props.auto_cutoff_hour_display ? 'at ' + this.props.auto_cutoff_hour_display : 'of reservation time')
        : null
      if (this.props.payment_rule === 'save_for_later' && this.props.can_save_card) {
        payment_rules = 'Save credit card details'
        auto_charge_details = auto_charge_label && (
          <modules.slideout.viewModeElement identifier="Auto-cancellation/no show charge policy" value={auto_charge_label} />
        )
        auto_cut_off_details = autoCutOffDisplay && (
          <modules.slideout.viewModeElement identifier="Auto-cancellation/no show charge cut off" value={autoCutOffDisplay} />
        )
      } else if (this.props.payment_setup) {
        payment_rules = this.props.currency_symbol + this.props.cost.toFixed(2)
        if (this.props.charge_type.startsWith('person')) {
          payment_rules += ' per person'
        }
        if (this.props.charge_type.endsWith('slot')) {
          payment_rules += ' per 15 minutes'
        }
        if (this.props.apply_service_charge) {
          var service_charge_rate =
            this.props.service_charge_type == 'DEFAULT_SERVICE_CHARGE' ? this.props.default_service_charge : this.props.service_charge
          payment_rules += ' + ' + service_charge_rate + '% service charge'
        }
        if (this.props.apply_tax_rate) {
          payment_rules += ' + tax'
        }
        if (this.props.apply_gratuity_charge) {
          if (this.props.gratuity_type == 'CLIENT_GRATUITY') {
            payment_rules += ' + gratuity'
          } else {
            var gratuity_rate = this.props.gratuity_type == 'DEFAULT_GRATUITY' ? this.props.default_gratuity : this.props.gratuity
            if (!gratuity_rate) {
              gratuity_rate = 0
            }
            payment_rules += ' + ' + gratuity_rate + '% gratuity'
          }
        }
        auto_charge_details = auto_charge_label && (
          <modules.slideout.viewModeElement identifier="Auto-refund policy" value={auto_charge_label} />
        )
        auto_cut_off_details = autoCutOffDisplay && (
          <modules.slideout.viewModeElement identifier="Auto-refund charge cut off" value={autoCutOffDisplay} />
        )
      }
      payment_details = (
        <div>
          <modules.slideout.viewModeElement
            identifier="Credit card required for"
            value={this.props.party_size_min ? 'Party sizes greater than ' + this.props.party_size_min : 'All party sizes'}
          />
          <modules.slideout.viewModeElement identifier="Payment rules" value={payment_rules} />
          {auto_charge_details}
          {auto_cut_off_details}
        </div>
      )
    }
    var cancelCutoffDisplay = !this.props.cancel_cutoff_type
      ? 'At any time'
      : this.props.cancel_cutoff_type === 'NEVER'
      ? 'Never'
      : 'Up until ' +
        ((this.props.cancel_cutoff_num || 'same') +
          ' ' +
          this.props.cancel_cutoff_type.toLowerCase() +
          (this.props.cancel_cutoff_num ? ' before ' : ' ') +
          (this.props.cancel_cutoff_hour_display ? 'at ' + this.props.cancel_cutoff_hour_display : 'reservation time'))
    var policyPrefix = ''
    var policyType = this.props.policy_type || 'default'
    var bookingPolicyType = this.state.policy_types[policyType] || ' '
    var policyDisplay = bookingPolicyType[0].toUpperCase() + bookingPolicyType.substr(1) + policyPrefix
    return (
      <div className="section" style={{ paddingTop: 30 }}>
        <modules.slideout.viewModeHeader label="Payment & Policy" />
        <modules.slideout.viewModeElement identifier="Payment requirements" value={this.props.require_credit_card ? 'Yes' : 'No'} />
        <modules.slideout.viewModeElement
          identifier="Allow this rule to be booked on channels that do not support credit cards"
          value={this.props.ignore_cc_for_3p_bookers ? 'Yes' : 'No'}
        />
        {payment_details}
        <modules.slideout.viewModeElement identifier="Customer can modify/cancel" value={cancelCutoffDisplay} />
        <modules.slideout.viewModeElement identifier="Policy" value={policyDisplay} />
      </div>
    )
  },
})
